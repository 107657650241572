import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
/*
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
*/

import projImg1 from "../assets/img/midwit_to_one_dollar.png";
import projImg2 from "../assets/img/midwit_backstory.png";
import projImg3 from "../assets/img/interview_triangle.png";
import projImg4 from "../assets/img/interview_macro.png";
import projImg5 from "../assets/img/interview_pro.png";
import projImg6 from "../assets/img/interview_wif.png";
import projImg7 from "../assets/img/interview_capo.png";
import admenturs_uf_eron from "../assets/img/admenturs_uf_eron.png";



import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Memes = () => {

  const memes = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg6,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg7,
    }
  ];

  return (
    <section className="meme" id="about">
      
      <Container>
      
      <h1>
      About
    </h1>
      <img src={projImg2} alt="Header Img" className="meme_img"/>
      
      
      <h1>
      Trading Advice
    </h1>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div id='advice'>
                <Row>
                        {
                          memes.map((meme, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...meme}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
