import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/midwit_meme.png";
import headerNameImg from "../assets/img/Eron Moosk Name.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import navIcon1 from '../assets/img/nav-icon1 black.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1><br/>$midwit</h1>
                  <div>
                    
                  <p>
                  Don't Midwit This, Anon!
                  <p/>

                  </p>
                    <p>

                      CA: 6iyno4fXFrdzUv56GfXZ8kUsqkdhQaMm2a8X8Dg8zNkt
                    </p>
                  </div>
                  <div className="social-icon">
                    <a href="https://twitter.com/MidwitOnSolana"><img src={navIcon1} alt="" /></a>
                    <a href="https://t.me/MidwitOnSolana"><img src={navIcon2} alt="" /></a>
                    <a href="https://dexscreener.com/"><img src={navIcon3} alt="" /></a>
                    <a href="https://solscan.io/token/6iyno4fXFrdzUv56GfXZ8kUsqkdhQaMm2a8X8Dg8zNkt"><img src={navIcon4} alt="" /></a>
                </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>


      </Container>
    </section>
  )
}
